var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hold-transition"},[(_vm.cargando)?_c('loading'):_vm._e(),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"content-wrapper"},[_c('section',{staticClass:"content-header"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('b',[_c('p',{staticClass:"mb-0 ml-2",staticStyle:{"font-size":"20px"}},[_vm._v(" "+_vm._s(_vm.accion)+" Informe ")])])]),_c('div',{staticClass:"col-sm-6"},[_c('ol',{staticClass:"breadcrumb float-sm-right"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Home")])],1),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Hidrocarburos")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Básico")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Configuración Informe")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v(_vm._s(_vm.accion)+" Reporte")])])])])])]),_c('a',{staticClass:"ir-arriba",on:{"click":function($event){return _vm.irArriba()}}},[_vm._m(0)]),_c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('fieldset',{staticClass:"well card"},[_c('legend',{staticClass:"well-legend text-bold bg-frontera text-light"},[_vm._v(" Datos Generales ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 mb-3"},[_c('div',{staticClass:"small-box alert-default-light",staticStyle:{"min-height":"100px"}},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"col-md-8"},[_vm._m(1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formGeneral.nombre),expression:"formGeneral.nombre"}],staticClass:"form-control form-control-sm",class:_vm.$v.formGeneral.nombre.$invalid
                            ? 'is-invalid'
                            : 'is-valid',attrs:{"type":"text","id":"nombre"},domProps:{"value":(_vm.formGeneral.nombre)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formGeneral, "nombre", $event.target.value)}}})])]),_vm._m(2)])]),_c('div',{staticClass:"col-md-4 mb-3"},[_c('div',{staticClass:"small-box alert-default-light",staticStyle:{"min-height":"100px"}},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"col-md-8"},[_vm._m(3),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formGeneral.permiso),expression:"formGeneral.permiso"}],staticClass:"form-control form-control-sm",class:_vm.$v.formGeneral.permiso.$invalid
                            ? 'is-invalid'
                            : 'is-valid',attrs:{"type":"text","id":"permiso"},domProps:{"value":(_vm.formGeneral.permiso)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formGeneral, "permiso", $event.target.value)}}})])]),_vm._m(4)])]),_c('div',{staticClass:"col-md-4 mb-3"},[_c('div',{staticClass:"small-box alert-default-light",staticStyle:{"min-height":"100px"}},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"col-md-8"},[_vm._m(5),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formGeneral.version),expression:"formGeneral.version"}],staticClass:"form-control form-control-sm",class:_vm.$v.formGeneral.version.$invalid
                            ? 'is-invalid'
                            : 'is-valid',attrs:{"type":"text","id":"version"},domProps:{"value":(_vm.formGeneral.version)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formGeneral, "version", $event.target.value)}}})])]),_vm._m(6)])]),_c('div',{staticClass:"col-md-4 mb-3"},[_c('div',{staticClass:"small-box alert-default-light",staticStyle:{"min-height":"100px"}},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"col-sm-8"},[_vm._m(7),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formGeneral.codigo),expression:"formGeneral.codigo"}],staticClass:"form-control form-control-sm",class:_vm.$v.formGeneral.codigo.$invalid
                            ? 'is-invalid'
                            : 'is-valid',attrs:{"type":"text","id":"codigo"},domProps:{"value":(_vm.formGeneral.codigo)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formGeneral, "codigo", $event.target.value)}}})])]),_vm._m(8)])]),_c('div',{staticClass:"col-md-8 mb-3"},[_c('div',{staticClass:"small-box alert-default-light",staticStyle:{"min-height":"100px"}},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"col-md-10"},[_vm._m(9),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formGeneral.observaciones),expression:"formGeneral.observaciones"}],staticClass:"form-control form-control-sm",class:_vm.$v.formGeneral.observaciones.$invalid
                            ? 'is-invalid'
                            : 'is-valid',attrs:{"type":"text","id":"observaciones"},domProps:{"value":(_vm.formGeneral.observaciones)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formGeneral, "observaciones", $event.target.value)}}})])]),_vm._m(10)])])])]),_c('fieldset',{staticClass:"well card"},[_c('legend',{staticClass:"well-legend text-bold bg-frontera text-light"},[_vm._v(" Campos A Seleccionar ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"card alert-default-primary"},[_vm._m(11),_c('div',{staticClass:"card-body",staticStyle:{"display":"block"}},[_c('table',{staticClass:"table table-sm table-bordered"},[_vm._m(12),_c('tbody',_vm._l((_vm.camposGenerales),function(general,indice){return _c('tr',{key:indice},[_c('td',[_vm._v(_vm._s(general.nombre))]),_c('td',[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"custom-control custom-switch custom-switch-off-danger custom-switch-on-success"},[_c('input',{staticClass:"custom-control-input",class:'customSwitch' + general.id,attrs:{"type":"checkbox","id":'customSwitch' + general.id + '-' + 1},domProps:{"checked":_vm.checkInput(general.id)},on:{"change":function($event){return _vm.selectDato(
                                        'General',
                                        general.id,
                                        general.nombre
                                      )}}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":'customSwitch' + general.id + '-' + 1}})])])])])])}),0)])])])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"card alert-default-info"},[_vm._m(13),_c('div',{staticClass:"card-body"},[_c('table',{staticClass:"table table-sm table-bordered"},[_vm._m(14),_c('tbody',_vm._l((_vm.camposFechas),function(dataFecha,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(dataFecha.nombre))]),_c('td',[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"custom-control custom-switch custom-switch-off-danger custom-switch-on-success"},[_c('input',{staticClass:"custom-control-input",class:'customSwitch' + dataFecha.id,attrs:{"type":"checkbox","id":'customSwitch' + dataFecha.id + '-' + 2},domProps:{"checked":_vm.checkInput(dataFecha.id)},on:{"change":function($event){return _vm.selectDato(
                                        'Fecha',
                                        dataFecha.id,
                                        dataFecha.nombre
                                      )}}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":'customSwitch' + dataFecha.id + '-' + 2}})])])])])])}),0)])])])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"card alert-default-success"},[_vm._m(15),_c('div',{staticClass:"card-body"},[_c('table',{staticClass:"table table-sm table-bordered"},[_vm._m(16),_c('tbody',_vm._l((_vm.camposMedidas),function(dataMedida,posicion){return _c('tr',{key:posicion},[_c('td',[_vm._v(_vm._s(dataMedida.nombre))]),_c('td',[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"custom-control custom-switch custom-switch-off-danger custom-switch-on-success"},[_c('input',{staticClass:"custom-control-input",class:'customSwitch' + dataMedida.id,attrs:{"type":"checkbox","id":'customSwitch' + dataMedida.id + '-' + 3},domProps:{"checked":_vm.checkInput(dataMedida.id)},on:{"change":function($event){return _vm.selectDato(
                                        'Medida',
                                        dataMedida.id,
                                        dataMedida.nombre
                                      )}}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":'customSwitch' + dataMedida.id + '-' + 3}})])])])])])}),0)])])])])])]),(_vm.formReporte.length > 0)?_c('fieldset',{staticClass:"well card"},[_c('legend',{staticClass:"well-legend text-bold bg-frontera text-light"},[_vm._v(" Campos Informe ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"card-body"},[_c('table',{staticClass:"table table-sm table-bordered"},[_vm._m(17),_c('tbody',_vm._l((_vm.formReporte),function(reporte,index){return _c('tr',{key:index,class:{
                        dragging: index === _vm.celdaArrastrada,
                        'drag-active': _vm.isDragActive,
                      },attrs:{"draggable":"true"},on:{"dragstart":function($event){return _vm.handleDragStart(index)},"dragover":function($event){$event.preventDefault();return _vm.allowDrop.apply(null, arguments)},"drop":function($event){return _vm.handleDrop(index)}}},[_c('td',[_vm._v(_vm._s(reporte.clasificacion))]),_c('td',[_vm._v(_vm._s(reporte.nombre))]),_c('td',[(
                            _vm.actualizarReporte && _vm.idActualizarReporte == index
                          )?_c('span',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.alias),expression:"formData.alias"}],staticClass:"form-control form-control-sm",attrs:{"type":"text","id":"nombre"},domProps:{"value":(_vm.formData.alias)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "alias", $event.target.value)}}})]):_c('span',[_vm._v(" "+_vm._s(reporte.alias))])]),_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',{staticClass:"text-center"},[(
                            _vm.actualizarReporte && _vm.idActualizarReporte == index
                          )?_c('span',[_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.formData.alias),expression:"formData.alias"}],staticClass:"btn btn-sm bg-success",attrs:{"type":"button"},on:{"click":function($event){return _vm.saveInforme(index)}}},[_c('i',{staticClass:"fas fa-save"})]),_c('button',{staticClass:"btn btn-sm bg-danger",on:{"click":function($event){return _vm.cancelarInforme()}}},[_c('i',{staticClass:"fas fa-ban"})])]):_c('span',[_c('button',{staticClass:"btn btn-sm bg-navy",attrs:{"type":"button"},on:{"click":function($event){return _vm.editarInforme(index)}}},[_c('i',{staticClass:"fas fa-edit"})]),_c('button',{staticClass:"btn btn-sm btn-danger",attrs:{"type":"button"},on:{"click":function($event){return _vm.destroyInforme(index)}}},[_c('i',{staticClass:"fas fa-trash-alt"})])])])])}),0)])])])]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-6"},[(
                  _vm.$store.getters.can(
                    'hidrocarburos.configuracionInformes.create'
                  ) ||
                    _vm.$store.getters.can(
                      'hidrocarburos.configuracionInformes.edit'
                    ) ||
                    _vm.$store.getters.can(
                      'hidrocarburos.configuracionInformes.save'
                    )
                )?_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.formGeneral.$invalid),expression:"!$v.formGeneral.$invalid"}],staticClass:"btn bg-primary col-md-3",on:{"click":function($event){return _vm.save()}}},[_c('i',{staticClass:"fas fa-save"}),_c('br'),_vm._v("Guardar ")]):_vm._e(),_c('button',{staticClass:"btn bg-secondary col-md-3",on:{"click":function($event){return _vm.back()}}},[_c('i',{staticClass:"fas fa-reply"}),_c('br'),_vm._v("Volver ")])])])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"fa-stack"},[_c('i',{staticClass:"fa fa-circle fa-stack-2x"}),_c('i',{staticClass:"fa fa-arrow-up fa-stack-1x fa-inverse"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',[_c('b',[_vm._v("Nombre Reporte")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon icon-sm"},[_c('i',{staticClass:"ion ion-compose text-black"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',[_c('b',[_vm._v("Permiso")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"ion ion-locked text-black"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',[_c('b',[_vm._v("Versión")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"ion ion-podium text-black"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',[_c('b',[_vm._v("Código")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"ion ion-pound text-black",staticStyle:{"font-size":"55px"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',[_c('b',[_vm._v("Observación")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"ion ion-chatbubbles text-black"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h3',{staticClass:"card-title"},[_vm._v("Datos Generales")]),_c('div',{staticClass:"card-tools"},[_c('button',{staticClass:"btn btn-tool",attrs:{"type":"button","data-card-widget":"collapse"}},[_c('i',{staticClass:"fas fa-minus"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"bg-dark"},[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v("Nombre")]),_c('th',{staticClass:"text-center"},[_vm._v("Acción")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h3',{staticClass:"card-title"},[_vm._v("Datos Fechas")]),_c('div',{staticClass:"card-tools"},[_c('button',{staticClass:"btn btn-tool",attrs:{"type":"button","data-card-widget":"collapse"}},[_c('i',{staticClass:"fas fa-minus"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"bg-dark"},[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v("Nombre")]),_c('th',{staticClass:"text-center"},[_vm._v("Acción")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h3',{staticClass:"card-title"},[_vm._v("Datos Medidas")]),_c('div',{staticClass:"card-tools"},[_c('button',{staticClass:"btn btn-tool",attrs:{"type":"button","data-card-widget":"collapse"}},[_c('i',{staticClass:"fas fa-minus"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"bg-dark"},[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v("Nombre")]),_c('th',{staticClass:"text-center"},[_vm._v("Acción")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"bg-dark"},[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v("Clasificación")]),_c('th',{staticClass:"text-center"},[_vm._v("Nombre")]),_c('th',{staticClass:"text-center"},[_vm._v("Alias")]),_c('th',{staticClass:"text-center"},[_vm._v("Posición")]),_c('th',{staticClass:"text-center"},[_vm._v("Acción")])])])
}]

export { render, staticRenderFns }