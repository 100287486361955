<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <b
                  ><p class="mb-0 ml-2" style="font-size: 20px">
                    {{ accion }} Informe
                  </p></b
                >
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Básico</li>
                  <li class="breadcrumb-item active">Configuración Informe</li>
                  <li class="breadcrumb-item active">{{ accion }} Reporte</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <a class="ir-arriba" @click="irArriba()">
          <span class="fa-stack">
            <i class="fa fa-circle fa-stack-2x"></i>
            <i class="fa fa-arrow-up fa-stack-1x fa-inverse"></i>
          </span>
        </a>
        <section class="content">
          <div class="container-fluid">
            <!-- Secciones Datos Generales -->
            <fieldset class="well card">
              <legend class="well-legend text-bold bg-frontera text-light">
                Datos Generales
              </legend>

              <div class="row">
                <!-- Nombre Reporte -->
                <div class="col-md-4 mb-3">
                  <div
                    class="small-box alert-default-light"
                    style="min-height: 100px;"
                  >
                    <div class="inner">
                      <div class="col-md-8">
                        <h5><b>Nombre Reporte</b></h5>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          id="nombre"
                          v-model="formGeneral.nombre"
                          :class="
                            $v.formGeneral.nombre.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        />
                      </div>
                    </div>
                    <div class="icon icon-sm">
                      <i class="ion ion-compose text-black"></i>
                    </div>
                  </div>
                </div>
                <!-- Permiso -->
                <div class="col-md-4 mb-3">
                  <div
                    class="small-box alert-default-light"
                    style="min-height: 100px;"
                  >
                    <div class="inner">
                      <div class="col-md-8">
                        <h5><b>Permiso</b></h5>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          id="permiso"
                          v-model="formGeneral.permiso"
                          :class="
                            $v.formGeneral.permiso.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        />
                      </div>
                    </div>
                    <div class="icon">
                      <i class="ion ion-locked text-black"></i>
                    </div>
                  </div>
                </div>
                <!-- Versión -->
                <div class="col-md-4 mb-3">
                  <div
                    class="small-box alert-default-light"
                    style="min-height: 100px;"
                  >
                    <div class="inner">
                      <div class="col-md-8">
                        <h5><b>Versión</b></h5>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          id="version"
                          v-model="formGeneral.version"
                          :class="
                            $v.formGeneral.version.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        />
                      </div>
                    </div>
                    <div class="icon">
                      <i class="ion ion-podium text-black"></i>
                    </div>
                  </div>
                </div>
                <!-- Código -->
                <div class="col-md-4 mb-3">
                  <div
                    class="small-box alert-default-light"
                    style="min-height: 100px;"
                  >
                    <div class="inner">
                      <div class="col-sm-8">
                        <h5><b>Código</b></h5>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          id="codigo"
                          v-model="formGeneral.codigo"
                          :class="
                            $v.formGeneral.codigo.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        />
                      </div>
                    </div>
                    <div class="icon">
                      <i
                        class="ion ion-pound text-black"
                        style="font-size: 55px;"
                      ></i>
                    </div>
                  </div>
                </div>
                <!-- Observaciones -->
                <div class="col-md-8 mb-3">
                  <div
                    class="small-box alert-default-light"
                    style="min-height: 100px;"
                  >
                    <div class="inner">
                      <div class="col-md-10">
                        <h5><b>Observación</b></h5>
                        <textarea
                          type="text"
                          class="form-control form-control-sm"
                          id="observaciones"
                          v-model="formGeneral.observaciones"
                          :class="
                            $v.formGeneral.observaciones.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        ></textarea>
                      </div>
                    </div>
                    <div class="icon">
                      <i class="ion ion-chatbubbles text-black"></i>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
            <!-- Secciones Campos A Seleccionar -->
            <fieldset class="well card">
              <legend class="well-legend text-bold bg-frontera text-light">
                Campos A Seleccionar
              </legend>
              <div class="row">
                <!-- Datos General -->
                <div class="col-md-4">
                  <div class="card alert-default-primary">
                    <div class="card-header">
                      <h3 class="card-title">Datos Generales</h3>
                      <div class="card-tools">
                        <button
                          type="button"
                          class="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i class="fas fa-minus"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body" style="display: block;">
                      <table class="table table-sm table-bordered">
                        <thead class="bg-dark">
                          <tr>
                            <th class="text-center">Nombre</th>
                            <th class="text-center">Acción</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(general, indice) in camposGenerales"
                            :key="indice"
                          >
                            <td>{{ general.nombre }}</td>
                            <td>
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <div
                                    class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success"
                                  >
                                    <input
                                      type="checkbox"
                                      class="custom-control-input"
                                      :id="
                                        'customSwitch' + general.id + '-' + 1
                                      "
                                      :class="'customSwitch' + general.id"
                                      :checked="checkInput(general.id)"
                                      @change="
                                        selectDato(
                                          'General',
                                          general.id,
                                          general.nombre
                                        )
                                      "
                                    />
                                    <label
                                      class="custom-control-label"
                                      :for="
                                        'customSwitch' + general.id + '-' + 1
                                      "
                                    ></label>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- Datos Fechas -->
                <div class="col-md-4">
                  <div class="card alert-default-info">
                    <div class="card-header">
                      <h3 class="card-title">Datos Fechas</h3>
                      <div class="card-tools">
                        <button
                          type="button"
                          class="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i class="fas fa-minus"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body">
                      <table class="table table-sm table-bordered">
                        <thead class="bg-dark">
                          <tr>
                            <th class="text-center">Nombre</th>
                            <th class="text-center">Acción</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(dataFecha, index) in camposFechas"
                            :key="index"
                          >
                            <td>{{ dataFecha.nombre }}</td>
                            <td>
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <div
                                    class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success"
                                  >
                                    <input
                                      type="checkbox"
                                      class="custom-control-input"
                                      :id="
                                        'customSwitch' + dataFecha.id + '-' + 2
                                      "
                                      :class="'customSwitch' + dataFecha.id"
                                      :checked="checkInput(dataFecha.id)"
                                      @change="
                                        selectDato(
                                          'Fecha',
                                          dataFecha.id,
                                          dataFecha.nombre
                                        )
                                      "
                                    />
                                    <label
                                      class="custom-control-label"
                                      :for="
                                        'customSwitch' + dataFecha.id + '-' + 2
                                      "
                                    ></label>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- Datos Medidas -->
                <div class="col-md-4">
                  <div class="card alert-default-success">
                    <div class="card-header">
                      <h3 class="card-title">Datos Medidas</h3>
                      <div class="card-tools">
                        <button
                          type="button"
                          class="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i class="fas fa-minus"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body">
                      <table class="table table-sm table-bordered">
                        <thead class="bg-dark">
                          <tr>
                            <th class="text-center">Nombre</th>
                            <th class="text-center">Acción</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(dataMedida, posicion) in camposMedidas"
                            :key="posicion"
                          >
                            <td>{{ dataMedida.nombre }}</td>
                            <td>
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <div
                                    class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success"
                                  >
                                    <input
                                      type="checkbox"
                                      class="custom-control-input"
                                      :id="
                                        'customSwitch' + dataMedida.id + '-' + 3
                                      "
                                      :checked="checkInput(dataMedida.id)"
                                      :class="'customSwitch' + dataMedida.id"
                                      @change="
                                        selectDato(
                                          'Medida',
                                          dataMedida.id,
                                          dataMedida.nombre
                                        )
                                      "
                                    />
                                    <label
                                      class="custom-control-label"
                                      :for="
                                        'customSwitch' + dataMedida.id + '-' + 3
                                      "
                                    ></label>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
            <!-- Campos Informe -->
            <fieldset class="well card" v-if="formReporte.length > 0">
              <legend class="well-legend text-bold bg-frontera text-light">
                Campos Informe
              </legend>
              <div class="row">
                <div class="card-body">
                  <table class="table table-sm table-bordered">
                    <thead class="bg-dark">
                      <tr>
                        <th class="text-center">Clasificación</th>
                        <th class="text-center">Nombre</th>
                        <th class="text-center">Alias</th>
                        <th class="text-center">Posición</th>
                        <th class="text-center">Acción</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(reporte, index) in formReporte"
                        :key="index"
                        :class="{
                          dragging: index === celdaArrastrada,
                          'drag-active': isDragActive,
                        }"
                        draggable="true"
                        @dragstart="handleDragStart(index)"
                        @dragover.prevent="allowDrop"
                        @drop="handleDrop(index)"
                      >
                        <td>{{ reporte.clasificacion }}</td>
                        <td>{{ reporte.nombre }}</td>
                        <td>
                          <span
                            v-if="
                              actualizarReporte && idActualizarReporte == index
                            "
                          >
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              id="nombre"
                              v-model="formData.alias"
                            />
                          </span>
                          <span v-else> {{ reporte.alias }}</span>
                        </td>
                        <td>{{ index + 1 }}</td>
                        <td class="text-center">
                          <span
                            v-if="
                              actualizarReporte && idActualizarReporte == index
                            "
                          >
                            <!-- Botón para mostrar el formulario de actualizar -->
                            <button
                              type="button"
                              class="btn btn-sm bg-success"
                              v-show="formData.alias"
                              @click="saveInforme(index)"
                            >
                              <i class="fas fa-save"></i>
                            </button>
                            <button
                              class="btn btn-sm bg-danger"
                              @click="cancelarInforme()"
                            >
                              <i class="fas fa-ban"></i></button
                          ></span>
                          <span v-else>
                            <button
                              type="button"
                              class="btn btn-sm bg-navy"
                              @click="editarInforme(index)"
                            >
                              <i class="fas fa-edit"></i>
                            </button>
                            <button
                              type="button"
                              class="btn btn-sm btn-danger"
                              v-on:click="destroyInforme(index)"
                            >
                              <i class="fas fa-trash-alt"></i>
                            </button>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </fieldset>
            <!-- Botón Guardar -->
            <div class="row">
              <div class="form-group col-md-6">
                <button
                  class="btn bg-primary col-md-3"
                  v-show="!$v.formGeneral.$invalid"
                  v-if="
                    $store.getters.can(
                      'hidrocarburos.configuracionInformes.create'
                    ) ||
                      $store.getters.can(
                        'hidrocarburos.configuracionInformes.edit'
                      ) ||
                      $store.getters.can(
                        'hidrocarburos.configuracionInformes.save'
                      )
                  "
                  @click="save()"
                >
                  <i class="fas fa-save"></i><br />Guardar
                </button>
                <button class="btn bg-secondary col-md-3" @click="back()">
                  <i class="fas fa-reply"></i><br />Volver
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
import $ from "jquery";
//import vSelect from "vue-select";

$(document).ready(function() {
  $(window).scroll(function() {
    if ($(this).scrollTop() > 0) {
      $(".ir-arriba").slideDown(600);
    } else {
      $(".ir-arriba").slideUp(600);
    }
  });
});

export default {
  name: "ConfiguracionInformeForm",
  components: {
    //vSelect,
    Loading,
  },
  data() {
    return {
      cargando: false,
      metodo: null,
      accion: null,
      actualizarReporte: false,
      idActualizarReporte: null,
      camposGenerales: {},
      camposFechas: {},
      camposMedidas: {},
      dataGeneral: {},
      formReporte: [],
      celdaArrastrada: null,
      isDragActive: false,
      formData: {
        campo_lista_informe_hidro_id: null,
        nombre: null,
        alias: null,
      },
      formGeneral: {
        id: null,
        nombre: null,
        permiso: null,
        codigo: null,
        version: null,
        observaciones: null,
      },
    };
  },
  validations: {
    formGeneral: {
      nombre: {
        required,
        customValidation: function(value) {
          // Expresión regular que permite solo letras, números y espacios
          const regex = /^[a-zA-Z0-9\s]+$/;
          return regex.test(value);
        },
      },
      permiso: {
        required,
      },
      codigo: {
        required,
      },
      version: {
        required,
      },
      observaciones: {
        required,
      },
    },
  },

  methods: {
    async init() {
      this.cargando = true;
      this.accion = this.$route.params.accion;
      await axios
        .get("/api/hidrocarburos/configuracionInformes/showCamposInforme")
        .then(async (response) => {
          this.cargando = false;
          let campos = response.data;
          this.camposGenerales = campos.camposGenerales;
          this.camposFechas = campos.camposFechas;
          this.camposMedidas = campos.camposMedidas;
          this.dataGeneral = campos.dataGeneral;

          if (this.$route.params.accion == "Editar") {
            this.formGeneral = this.$route.params.data_edit;
            this.formReporte = this.formGeneral.det_informes;
            await this.getData();
            this.metodo = "PUT";
          } else {
            this.metodo = "POST";
          }
        });
    },

    /* Guardar Informe */
    async save() {
      let opcion = true;
      if (this.formReporte.length == 0) {
        this.cargando = false;
        await this.$swal({
          icon: "warning",
          title: "Advertencia!!",
          text: "No ha seleccionado los campos. Por favor validar.",
          timer: 3000,
          backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
          timerProgressBar: true,
          allowOutsideClick: false,
          confirmButtonText: "Aceptar",
        });
        return false;
      } else if (this.formReporte.some((rep) => rep.alias === null)) {
        this.cargando = false;
        await this.$swal({
          icon: "warning",
          title: "Advertencia!!",
          text:
            "No ha asignado el alias a los campos del informe. Por favor validar.",
          //timer: 3000,
          backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
          //timerProgressBar: true,
          showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonText: "Aceptar",
        }).then((result) => {
          if (result.isDismissed == true) {
            opcion = false;
          }
        });
        if (opcion == false) {
          return false;
        }
      }

      if (!this.$v.formGeneral.$invalid) {
        this.cargando = true;
        axios({
          method: this.metodo,
          url: "/api/hidrocarburos/configuracionInformes/saveInforme",
          data: { informe: this.formGeneral, det_informe: this.formReporte },
        })
          .then((response) => {
            /*   this.formGeneral = response.data;
            this.formReporte = this.formGeneral.det_informes;
            this.getData(); */
            this.$route.params.accion = "Editar";
            this.$route.params.data_edit = response.data;
            this.init();
            this.$swal({
              text: "Se guardó el informe...",
              icon: "success",
              confirmButtonText: "Ok!",
            });
            this.cargando = false;
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          });
      }
    },

    /* LLenar Arreglo Al Seleccionar Los Campos */
    selectDato(clasificacion, id, nombre) {
      // Buscar el índice del elemento con el mismo id en el arreglo
      let index = this.formReporte.findIndex(
        (item) => item.campo_lista_informe_hidro_id === id
      );

      let exist_lista = this.formReporte.filter(
        (reporte) => reporte.campo_lista_informe_hidro_id === id
      );

      if (exist_lista.length > 0) {
        // Eliminar el elemento si existe en el arreglo
        if (index !== -1) {
          this.formReporte.splice(index, 1);
          this.formReporte.forEach((form, index) => {
            form.posicion = index + 1;
          });
        }
      } else {
        // Agregar el elemento si no existe en el arreglo
        if (index === -1) {
          this.formReporte.forEach((form, index) => {
            form.posicion = index + 1;
          });
          this.formReporte.push({
            id: null,
            campo_lista_informe_hidro_id: id,
            nombre: nombre,
            alias: null,
            clasificacion: clasificacion,
            posicion: this.formReporte.length + 1,
          });
        }
      }
    },

    /* Mostrar Data Edit */
    getData() {
      let reporteNew = [];
      this.formReporte.forEach((reporte) => {
        const filterReporte = this.dataGeneral.filter(
          (item) => item.id === reporte.campo_lista_informe_hidro_id
        );

        if (filterReporte.length > 0) {
          reporteNew.push({
            id: reporte.id,
            campo_lista_informe_hidro_id: reporte.campo_lista_informe_hidro_id,
            nombre: filterReporte[0].nombre,
            alias: reporte.alias,
            clasificacion:
              filterReporte[0].clasificacion == 1
                ? "General"
                : filterReporte[0].clasificacion == 2
                ? "Fecha"
                : "Medida",
            posicion: reporte.posicion,
          });
        }
      });
      this.formReporte.splice(0, this.formReporte.length);
      this.formReporte = reporteNew;
    },

    /* Pintar los checks */
    checkInput(id) {
      const filterReporte = this.formReporte.filter(
        (item) => item.campo_lista_informe_hidro_id === id
      );

      return filterReporte.length > 0 ? true : false;
    },

    /* Funciones Eventos de Arrastrar y Desplazamiento*/
    irArriba() {
      $("body,html").animate({ scrollTop: "0px" }, 1000);
    },

    back() {
      return this.$router.replace("/Hidrocarburos/ConfiguracionInforme");
    },

    handleDragStart(index) {
      this.celdaArrastrada = index;
      this.isDragActive = true;
    },

    allowDrop(event) {
      event.preventDefault();
    },

    handleDrop(targetIndex) {
      const tempRow = this.formReporte[this.celdaArrastrada];
      this.formReporte.splice(this.celdaArrastrada, 1);
      this.formReporte.splice(targetIndex, 0, tempRow);
      this.isDragActive = true;
      this.celdaArrastrada = null;

      this.formReporte.forEach((form, index) => {
        form.posicion = index + 1;
      });
    },

    /* Funciones Formulario Asignación Alias */
    editarInforme(index) {
      // Antes de mostrar el formulario de actualizar, rellenamos sus campos
      this.idActualizarReporte = index;
      this.formData.campo_lista_informe_hidro_id = this.formReporte[
        index
      ].campo_lista_informe_hidro_id;
      this.formData.nombre = this.formReporte[index].nombre;
      this.formData.alias = this.formReporte[index].alias;

      // Mostramos el formulario
      this.actualizarReporte = true;
    },

    saveInforme(index) {
      // Ocultamos nuestro formulario de actualizar
      this.actualizarReporte = false;

      // Actualizamos los datos
      this.formReporte[
        index
      ].campo_lista_informe_hidro_id = this.formData.campo_lista_informe_hidro_id;
      this.formReporte[index].alias = this.formData.alias;
    },

    cancelarInforme() {
      this.idActualizarReporte = -1;
    },

    destroyInforme(index) {
      this.formReporte.splice(index, 1);
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

.well {
  min-height: 20px;
  padding: 14px;
  margin-bottom: 10px;
  border: 2px solid #001871;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.well-legend {
  display: block;
  font-size: 20px;
  width: auto;
  min-width: 300px;
  padding: 2px 7px 2px 5px;
  line-height: unset;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.ir-arriba {
  display: none;
  background-repeat: no-repeat;
  font-size: 20px;
  color: black;
  cursor: pointer;
  position: fixed;
  bottom: 80px;
  right: 8px;
  z-index: 2;
}

.dragging {
  margin: 20px;
  height: auto;
  padding: 4px;
  background: rgba(black, 0.1);
  transition: $ease-out;
  border-radius: 15px;
  margin-left: auto;

  /* items grabbed state */
  &[draggable="true"] {
    background: linear-gradient(to bottom right, #ff1c79, #001871);
    color: #fff;
  }
}
.drag-active {
  opacity: 0.9; /* Opacidad para indicar que la fila está siendo arrastrada */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Sombreado para dar una sensación de elevación */
  cursor: pointer;
}
</style>
